import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {isEmpty} from "@core/utils/filter";

export default function statementreceiveUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'id', label: 'ID', sortable: true },
    { key: 'receive_no', label: '收款单编号', sortable: true },
    { key: 'type', label: '类型', sortable: true },
    { key: 'source', label: '收款来源', sortable: true },
    // { key: 'channel_id', label: '终端客户ID', sortable: true },
    { key: 'channel_name', label: '终端客户', sortable: true },
    // { key: 'pay_account', label: '付款账号', sortable: true },
    // { key: 'receive_account', label: '收款账号', sortable: true },
    // { key: 'company_id', label: '主体ID', sortable: true },
    { key: 'company_name', label: '主体名称', sortable: true },
    // { key: 'warehouse_id', label: '仓库ID', sortable: true },
    { key: 'store_name', label: '店铺', sortable: true },
    // { key: 'warehouse_name', label: '仓库名称', sortable: true },
    { key: 'reach_time', label: '到账日期', sortable: true },
    // { key: 'pay_method', label: '付款方式', sortable: true },
      { key: 'statement_pay_method', label: '付款方式', sortable: true },
    { key: 'receive_total', label: '收款金额', sortable: true },
    { key: 'srm_total', label: '实收金额'},
    { key: 'sc_total', label: '费用金额'},
    // { key: 'a', label: '陈列费', sortable: true },
    // { key: 'b', label: '退货扣款', sortable: true },
    // { key: 'memo', label: '备注', sortable: true },
    // { key: 'attachments', label: '素材包', sortable: true },
    { key: 'status', label: '审核状态', sortable: true },
    { key: 'reason', label: '驳回原因'},
    // { key: 'state', label: '启用', sortable: true },
    { key: 'create_time', label: '添加时间', sortable: true },
    { key: 'creator', label: '添加人', sortable: true },
    { key: 'update_time', label: '更新时间', sortable: true },
    { key: 'updator', label: '更新人', sortable: true },
        { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('receive_id')
  const isSortDirDesc = ref(true)
  const tabStatus = ref(2)
  const routeType = ref(0)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey,tabStatus,routeType], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    const condition = {...store.getters['statementreceive/getCondition']}
    if (routeType.value==0){
      routeType.value=config.type
    }
    // for (let i = 0; i < tableColumns.length; i++) {
    //   if (tableColumns[i].key=="sc_total"&&config.type==1){
    //     tableColumns.splice(i,1)
    //   }
    // }
      const storeArrays = []
      const storeArray = isEmpty(condition.storeArrays)?[]:condition.storeArrays
      for (let i = 0; i < storeArray.length; i++) {
          storeArrays.push(storeArray[i].value)
      }
    store
      .dispatch('statementreceive/search', {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
        receiveNo:condition.receiveNo,
        statementNo:condition.statementNo,
        relationalNo:condition.relationalNo,
        payMethod:condition.payMethod==null?null:condition.payMethod.value,
        status:tabStatus.value,
        createTime:condition.createTime,
        updateTime:condition.updateTime,
        reachTime:condition.reachTime,
        channelId:condition.channelId,
        warehouseId:condition.warehouseId,
        storeIds:storeArrays.join(','),
        companyId:condition.companyId,
        creator:condition.creatorId,
        type:routeType.value,
        source:condition.source==null?null:condition.source.value,
        statementPayMethod:condition.statementPayMethod,
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals
        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,
    tabStatus,
    routeType,

    // Extra Filters
  }
}
