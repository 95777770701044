<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
            <div class="demo-spacing-0">
              <b-alert
                  variant="primary"
                  show
              >
                <div class="alert-body">
                  <span><strong>
                    条数:{{ selected.length }}
                    <br>
                    收款金额:{{ price }}
                    <br>
                    实收金额:{{ actualAmount }}
                    <br>
                    费用金额:{{ feeAmount }}
                  </strong></span>
                </div>
              </b-alert>
            </div>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search"
                  class="mr-1"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-warning"
                  @click="batchAudit"
                  class="mr-1"
                  size="sm"
                  v-if="allowUser"
              >
                <feather-icon
                    icon="FileTextIcon"
                    class="mr-50"
                />
                <span class="align-middle">批量审核</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-success"
                  @click="selectAllRows"
                  class="mr-1"
                  size="sm"
              >
                <feather-icon
                    icon="CheckCircleIcon"
                    class="mr-50"
                />
                <span class="align-middle">全部勾选</span>
              </b-button>
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  @click="clearSelected"
                  class="mr-1"
                  size="sm"
              >
                <feather-icon
                    icon="Trash2Icon"
                    class="mr-50"
                />
                <span class="align-middle">清空勾选</span>
              </b-button>

<!--              <b-button-->
<!--                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"-->
<!--                  variant="outline-primary"-->
<!--                  @click="exportData"-->
<!--                  class="mr-1"-->
<!--                  size="sm"-->
<!--              >-->
<!--                <feather-icon-->
<!--                    icon="FileIcon"-->
<!--                    class="mr-50"-->
<!--                />-->
<!--                <span class="align-middle">导出</span>-->
<!--              </b-button>-->

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="FinanceExportData"
                  class="mr-1"
                  size="sm"
              >
                <feather-icon
                    icon="FileIcon"
                    class="mr-50"
                />
                <span class="align-middle">导出</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <b-card
            v-show="advanced_search_modal"
        >
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition"
          >
            <b-row>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="收款编号:"
                    label-for="order_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="state.condition.receiveNo"
                      name="receiveNo"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="结算单编号:"
                    label-for="order_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="state.condition.statementNo"
                      name="statementNo"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="关联编号:"
                    label-for="order_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="state.condition.relationalNo"
                      name="relationalNo"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <!--              <b-col-->
              <!--                  cols="12"-->
              <!--                  md="4"-->
              <!--              >-->
              <!--                <b-form-group-->
              <!--                    label-cols="3"-->
              <!--                    label-cols-lg="3"-->
              <!--                    label="状态"-->
              <!--                    label-for="status"-->
              <!--                    label-size="sm"-->
              <!--                >-->
              <!--                  <v-select-->
              <!--                      id="status"-->
              <!--                      :options="getCodeOptions('statement_receive_status')"-->
              <!--                      v-model="state.condition.status"-->
              <!--                      name="status"-->
              <!--                      class="select-size-sm"-->
              <!--                      placeholder="请选择状态"-->
              <!--                  />-->
              <!--                </b-form-group>-->
              <!--              </b-col>-->
              <b-col md="4" cols="12">
                <xy-input-button label="收款日期"
                                 type="time"
                                 v-on:change="fromChildren($event,['reachTime'])"
                                 :params="['reachTime']"
                                 :value="state.condition.reachTime"
                >
                </xy-input-button>
              </b-col>
              <b-col md="4" cols="12" class="mb-2">
                <xy-input-button label="创建日期"
                                 type="time"
                                 v-on:change="fromChildren($event,['createTime'])"
                                 :params="['createTime']"
                                 :value="state.condition.createTime"
                >
                </xy-input-button>
              </b-col>

              <b-col md="4" cols="12" class="mb-2">
                <xy-input-button label="审核日期"
                                 type="time"
                                 v-on:change="fromChildren($event,['updateTime'])"
                                 :params="['updateTime']"
                                 :value="state.condition.updateTime"
                >
                </xy-input-button>
              </b-col>

              <b-col md="4" cols="12" class="mb-1"
              >
                <xy-input-button
                    label="结算单位"
                    type="input"
                    v-on:change="fromChildren($event,['channelName','channelId'])"
                    :params="['channelName','channelId']"
                    :value="state.condition.channelName"
                    modalName="终端客户和渠道"
                    placeholder="点击选择结算单位"
                >
                </xy-input-button>
              </b-col>
              <b-col md="4" cols="12" class="mb-1"
              >
                <xy-input-button
                    label="主体"
                    type="input"
                    v-on:change="fromChildren($event,['companyName','companyId'])"
                    :params="['companyName','companyId']"
                    :value="state.condition.companyName"
                    modalName="销售主体"
                    placeholder="点击选择主体"
                >
                </xy-input-button>
              </b-col>
<!--              <b-col-->
<!--                  cols="12"-->
<!--                  md="4"-->
<!--              >-->
<!--                <xy-input-button-->
<!--                    label="仓库"-->
<!--                    type="input"-->
<!--                    v-on:change="fromChildren($event,['warehouseName','warehouseId'])"-->
<!--                    :params="['warehouseName','warehouseId']"-->
<!--                    :value="state.condition.warehouseName"-->
<!--                    modalName="仓库"-->
<!--                    placeholder="点击选择仓库"-->
<!--                >-->
<!--                </xy-input-button>-->
<!--              </b-col>-->
              <b-col
                  cols="12"
                  md="4"
              >
                <xy-input-button
                    label="创建人"
                    type="input"
                    v-on:change="fromChildren($event,['creatorName','creatorId'])"
                    :params="['creatorName','creatorId']"
                    :value="state.condition.creatorName"
                    modalName="创建人"
                    placeholder="点击选择创建人"
                >
                </xy-input-button>
              </b-col>
<!--              <b-col-->
<!--                  cols="12"-->
<!--                  md="4"-->
<!--              >-->
<!--                <xy-input-button-->
<!--                    label="店铺"-->
<!--                    type="input"-->
<!--                    v-on:change="fromChildren($event,['storeName','storeId'])"-->
<!--                    :params="['storeName','storeId']"-->
<!--                    :value="state.condition.storeName"-->
<!--                    modalName="店铺"-->
<!--                    placeholder="点击选择店铺"-->
<!--                >-->
<!--                </xy-input-button>-->
<!--              </b-col>-->

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="店铺:"
                    label-for="type"
                    label-size="sm"
                >
                  <v-select
                      v-model="condition.storeArrays"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      multiple
                      label="label"
                      :options="storeArray"
                      class="select-size-sm"
                      placeholder="请选择店铺"
                      :close-on-select="false"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="收款来源:"
                    label-for="type"
                    label-size="sm"
                >
                  <v-select
                      v-model="condition.source"
                      label="label"
                      :options="sourceArray"
                      class="select-size-sm"
                      placeholder="请选择收款来源"
                  />
                </b-form-group>
              </b-col>

               <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="收款方式:"
                    label-for="type"
                    label-size="sm"
                >
                  <v-select
                      v-model="state.condition.statementPayMethod"
                      label="label"
                      :options="getCodeOptions('statement_pay_method')"
                      class="select-size-sm"
                      placeholder="请选择收款方式"
                      :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search"
                >
                  <span class="align-right">关闭</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

      </div>
      <xy-tab
          :tabs="getCodeOptions('statement_receive_status')"
          :statusValue="status"
          v-on:emitTabs="function($event) {tabStatus = $event}"
      ></xy-tab>
      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          selectable
          selected-variant="success"
          select-mode="multi"
          @row-selected="onRowSelected"
      >

        <!-- full detail on click -->
        <template #row-details="row">
          <!--          <b-card v-for="i in row.item.ext.items" :key="i.name">-->
          <b-card>
            <div>
              <b-table striped hover :items="row.item.ext.item" :fields="fields" show-empty empty-text="未关联对账单">
                <template #cell(channel_name)="data">
                  {{ isEmpty(data.item.ext.statement)?'':data.item.ext.statement.channel_name }}
                </template>
                <template #cell(receivable_total)="data">
                  {{ isEmpty(data.item.ext.statement)?'':data.item.ext.statement.receivable_total }}
                </template>
                <template #cell(received_tatal)="data">
                  {{ isEmpty(data.item.ext.statement)?'':data.item.ext.statement.received_tatal }}
                </template>
                <template #cell(other_cost)="data">
                  {{ isEmpty(data.item.ext.statement)?'':data.item.ext.statement.other_cost }}
                </template>
                <template #cell(remain_total)="data">
                  {{ isEmpty(data.item.ext.statement)?'':data.item.ext.statement.remain_total }}
                </template>
                <template #cell(add_time)="data">
                  {{ isEmpty(data.item.ext.statement)?'':toDate(data.item.ext.statement.add_time) }}
                </template>
                <template #cell(statement_price)="data">
                  {{ data.item.ext.statementPrice }}
                </template>
                <template #cell(cost)="data">
                  {{ data.item.ext.cost }}
                </template>


              </b-table>
            </div>
          </b-card>
        </template>

        <template #cell(receive_no)="data">
          <b-form-checkbox v-model="data.detailsShowing"
                           name="check-button"
                           @change="data.toggleDetails"
                           button-variant="default"
                           size="sm"
                           button>
            <feather-icon
                icon="MinusSquareIcon"
                size="15"
                class="align-middle text-body"
                v-show="data.detailsShowing==true"
            />
            <feather-icon
                icon="PlusSquareIcon"
                size="16"
                class="align-middle text-body"
                v-show="data.detailsShowing==false"
            />
          </b-form-checkbox>
          <b-link
              :to="{ name: routeName, query: { id: data.item.id,type:type,readonlys:1 }}"
              target="_blank"
          >
            [#{{ data.item.id }}]{{ data.item.receive_no }}
          </b-link>
        </template>

        <template #cell(statement_pay_method)="data">
          <div :id="`remark-${data.item.id}`">
                     <span v-if="data.item.ext.method">
                       {{ data.item.ext.method.substring(0, 6) }}
                     <span v-if="data.item.ext.method.length>6">...</span>
                     </span>
          </div>
          <b-tooltip :target="`remark-${data.item.id}`" placement="top">
            {{ data.item.ext.method }}
          </b-tooltip>
        </template>

        <!-- Columns -->
        <template #cell(id)="data">
          #{{ data.item.id }}
        </template>

        <template #cell(type)="data">
          {{ data.item.type == 1 ? '线上' : '线下' }}
        </template>

        <!-- Column: Type -->
        <template #cell(reach_time)="data">
          {{ toDate(data.item.reach_time) }}
        </template>

        <template #cell(create_time)="data">
          {{ toDate(data.item.create_time) }}
        </template>

        <template #cell(update_time)="data">
          {{ toTime(data.item.update_time) }}
        </template>

        <template #cell(creator)="data">
          {{ getCodeLabel("user", data.item.creator) }}
        </template>

        <template #cell(updator)="data">
          {{ getCodeLabel("user", data.item.updator) }}
        </template>

        <template #cell(pay_method)="data">
          {{ getCodeLabel("statement_pay_method", data.item.pay_method) }}
        </template>

        <template #cell(receive_total)="data">
          {{ data.item.ext.receiveTotal }}
        </template>

        <template #cell(srm_total)="data">
          {{ data.item.ext.srmTotal }}
        </template>

        <template #cell(sc_total)="data">
          {{ data.item.ext.scTotal }}
        </template>

        <template #cell(source)="data">
          {{ data.item.source == 1?'手工创建':(data.item.source == 2?'流水生成':'未知来源') }}
        </template>

        <template #cell(reason)="data">
          <div :id="`remark-row-${data.item.id}`">
            <feather-icon icon="EditIcon" @click="showRemark(data.item)"/>
            {{ data.value.substring(0, 3) }}
            <span v-if="data.value.length > 3"> ...</span>
          </div>
          <b-tooltip
              :target="`remark-row-${data.item.id}`"
              placement="top"
          >
            {{ data.item.reason }}
          </b-tooltip>
        </template>

        <template #cell(status)="data">
          <!--          <span :class="'text-'+getCode('contract_status', data.item.status).color">{{getCodeLabel('contract_status', data.item.status)}}</span>-->
          <b-badge
              pill
              :variant="`light-${getCodeColor('statement_receive_status', data.item.status)}`"
          >
            {{ getCodeLabel("statement_receive_status", data.item.status) }}
          </b-badge>
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
              @click="changeState(data.item)"
          >
            <b-badge
                pill
                :variant="`light-$ {getCodeColor('state', data.item.state)}`"

            >
              {{ getCodeLabel('state', data.item.state) }}
            </b-badge>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >

            <template #button-content>
              <!--多菜单-->
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
                :to="{ name: routeName, query: { id: data.item.id,type:type,srmReadonly:1,otherReadonly:0 },params:{readonly:1} }">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">查看</span>
            </b-dropdown-item>

            <b-dropdown-item
                :to="{ name: routeName, query: { id: data.item.id,type:type,srmReadonly:1 },params:{readonly:0} }"
                v-if="(data.item.status == 1||data.item.status == 2) && data.item.ext.method.indexOf('现金代收')==-1">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

            <b-dropdown-item @click="del(data.item)" v-if="data.item.status == 1">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">删除</span>
            </b-dropdown-item>

            <div v-if="user.role_id==27||user.role_id==14">
              <b-dropdown-item @click="changeStatus(data.item,2,'提交审核')" v-if="data.item.status == 1">
                <feather-icon icon="FileTextIcon"/>
                <span class="align-middle ml-50">提交审核</span>
              </b-dropdown-item>
            </div>

            <div v-if="data.item.status == 2">
                <!--紧急发布 可以优化-->
              <div v-if="data.item.ext.isCreateByFinanceResource">
                <b-dropdown-item @click="changeStatus(data.item,3,'审核通过')"  v-if="data.item.ext.method.indexOf('现金代收')==-1">
                  <feather-icon icon="FileTextIcon"/>
                  <span class="align-middle ml-50">审核通过</span>
                </b-dropdown-item>

                <b-dropdown-item @click="showReject(data.item)" >
                  <feather-icon icon="FileTextIcon"/>
                  <span class="align-middle ml-50">驳回</span>
                </b-dropdown-item>
              </div>

              <div v-else>
                <b-dropdown-item @click="changeStatus(data.item,3,'审核通过')" v-if="allowUser && data.item.ext.method.indexOf('现金代收')==-1">
                  <feather-icon icon="FileTextIcon"/>
                  <span class="align-middle ml-50">审核通过</span>
                </b-dropdown-item>

                <b-dropdown-item @click="showReject(data.item)" v-if="allowUser">
                  <feather-icon icon="FileTextIcon"/>
                  <span class="align-middle ml-50">驳回</span>
                </b-dropdown-item>
              </div>
            </div>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <b-modal
        id="modal-remark"
        ok-only
        ok-title="确认"
        @ok="saveRemark"
        cancel-title="取消"
        centered
        size="lg"
        title="修改原因"
        ref="remarkModal"
    >
      <b-card>
        <b-form-textarea
            id="textarea"
            v-model="remarkInfo"
            rows="3"
            max-rows="6"
        />
      </b-card>
    </b-modal>

    <b-modal
        id="modal-remark"
        ok-only
        ok-title="确认"
        @ok="reject"
        cancel-title="取消"
        centered
        size="lg"
        title="驳回原因"
        ref="reject"
    >
      <b-card>
        <b-form-textarea
            id="textarea"
            v-model="remarkInfo"
            rows="3"
            max-rows="6"
        />
      </b-card>
    </b-modal>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted, reactive, onMounted, toRefs} from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  isEmpty
} from '@core/utils/filter'
import statementreceiveUseList from './statementreceiveUseList'
import statementreceiveStore from './statementreceiveStore'
import XyInputButton from "@/views/components/xy/XyInputButton";
import Ripple from "vue-ripple-directive";
import {useToast} from "vue-toastification/composition";
import {getUserData} from "@/auth/utils";
import XyTab from "@/views/components/xy/XyTab";
import {useRouter} from "@core/utils/utils";
import axios from "@/libs/axios";
import storeStore from "@/views/apps/store/storeStore";

export default {
  components: {
    XyTab,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    XyInputButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [
        {key: 'statement_no', label: '编号'},
        {key: 'channel_name', label: '终端客户'},
        {key: 'statement_price', label: '对账金额'},
        {key: 'cost', label: '费用金额'},
        {key: 'receivable_total', label: '结算金额'},
        {key: 'received_tatal', label: '已收金额'},
        // {key: 'other_cost', label: '其他费用'},
        {key: 'remain_total', label: '应收余额'},
        {key: 'receive_current', label: '本次收款'},
        {key: 'add_time', label: '结算日期'},
      ],
      advanced_search_modal: false,
      statementId: 0,

      checked: [],
      allchecked: false,
      indeterminate: false,
      isCheckedObj: {},
      table: [],
      user: {},
      status: '2',
      type: 0,
      selectedItem: {},
      remarkInfo: '',
      routeName: '',
      selected: [],
      price: 0,
      actualAmount: 0,
      feeAmount: 0,
      storeArray:[],
      allowUser:false,
      sourceArray:[{label:'手工创建',value:1},{label:'流水生成',value:2}]
    }
  },
  methods: {
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('statementreceive/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
    searchByCondition() {
      this.refetchData()
    },
    resetCondition() {
      this.state.condition = {}
      store.commit('statementreceive/updateCondition', this.state.condition)
      this.refetchData();
    },
    showRemark(item) {
      this.selectedItem = item
      this.remarkInfo = item.reason
      this.$refs['remarkModal'].show()
    },
    showReject(item) {
      this.selectedItem = item
      this.remarkInfo = item.reason
      this.$refs['reject'].show()
    },
    onRowSelected(items) {
      this.selected = items
    },
    selectAllRows() {
      this.$refs.refListTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.refListTable.clearSelected()
    },
  },
  setup() {
    const toast = useToast()
    const router = useRouter()

    const state = reactive({
      condition: {},
    })

    onMounted(() => {
      state.condition = store.getters['statementreceive/getCondition']
    })

    // Register module
    if (!store.hasModule('statementreceive')) store.registerModule('statementreceive', statementreceiveStore)
    if (!store.hasModule('store')) store.registerModule('store', storeStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('statementreceive')) store.unregisterModule('statementreceive')
      if (store.hasModule('store')) store.unregisterModule('store')
    })

    const advanced_search = function () {
      this.advanced_search_modal = this.advanced_search_modal ? false : true;
    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        state.condition[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }

    const changeStatus = function (params, status, information) {
      if (confirm("是否" + information)) {
        store.dispatch('statementreceive/changeStatus', {id: params.receive_id, status: status}).then(res => {
          if (res.data.code == 0) {
            toast.success("操作成功")
            refetchData()
          } else {
            toast.error(res.data.data)
          }
        })
      }
    }

    const saveRemark = function () {
      this.selectedItem.reason = this.remarkInfo
      store.dispatch('statementreceive/saveReason', {
        id: this.selectedItem.receive_id,
        reason: this.selectedItem.reason
      }).then(res => {
        if (res.data.code === 0) {
          toast.success("修改成功")
          refetchData()
        } else {
          toast.error("修改失败")
        }
      })
    }

    const reject = function () {
      this.selectedItem.reason = this.remarkInfo
      store.dispatch('statementreceive/reject', {
        id: this.selectedItem.receive_id,
        reason: this.selectedItem.reason
      }).then(res => {
        if (res.data.code === 0) {
          toast.success("驳回成功")
          refetchData()
        } else {
          toast.error(res.data.data)
        }
      })
    }

    const del = function (params) {
      if (confirm("是否删除")) {
        store.dispatch('statementreceive/del', {id: params.receive_id}).then(res => {
          if (res.data.code == 0) {
            toast.success("操作成功")
            refetchData()
          } else {
            toast.error(res.data.data)
          }
        })
      }
    }

    const batchAudit = function () {
      if (this.selected.length == 0) {
        toast.error('请选择数据!')
        return false
      }
      for (let i = 0; i < this.selected.length; i++) {
        if (this.selected[i].status != 2) {
          toast.error('只有审核中状态才能审核通过!')
          return false
        }
        if (this.selected[i].ext.method.indexOf('现金代收')!=-1){
          toast.error('存在现金代收单据，不能审核通过!')
          return false
        }
      }
      var promise = Promise.resolve()
      if (confirm("是否批量审核通过?")) {
        for (let i = 0; i < this.selected.length; i++) {
          promise = promise.then(() => {
            return new Promise((resolve, reject) => {
              store.dispatch('statementreceive/changeStatus', {
                id: this.selected[i].receive_id,
                status: 3
              }).then(res => {
                if (res.data.code == 1) {
                  toast.error(res.data.data)
                }
                resolve()
              })
            })
          })
        }
      }
      promise.then(() => {
        refetchData()
        toast.success("审核成功!")
      })
    }

    const exportData = function () {
      const storeArrays = []
      const storeArray = isEmpty(state.condition.storeArrays)?[]:state.condition.storeArrays
      for (let i = 0; i < storeArray.length; i++) {
        storeArrays.push(storeArray[i].value)
      }
      axios({
            url: '/api/exportexcelfile/createStatementReceiveExcel',
            method: 'get',
            params: {
              receiveNo: state.condition.receiveNo,
              statementNo: state.condition.statementNo,
              relationalNo: state.condition.relationalNo,
              payMethod: state.condition.payMethod == null ? null : state.condition.payMethod.value,
              status: tabStatus.value,
              createTime: state.condition.createTime,
              updateTime: state.condition.updateTime,
              reachTime: state.condition.reachTime,
              channelId: state.condition.channelId,
              warehouseId: state.condition.warehouseId,
              storeIds:storeArrays.join(','),
              companyId: state.condition.companyId,
              creator: state.condition.creatorId,
              type: routeType.value,
              source:state.condition.source == null ? null : state.condition.source.value,
              statementPayMethod:state.condition.statementPayMethod,
            },
            responseType: 'blob',
          }
      ).then(res => {
        toast.success("导出成功")
        let data = new Blob([res.data], {type: 'application/vnd.ms-excel'})   //接收的是blob，若接收的是文件流，需要转化一下
        let filename = "收款单-" + new Date().getTime();
        /*-------------*/
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = URL.createObjectURL(data);
        link.setAttribute('download', filename + '.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    }

    const FinanceExportData = function () {
      const storeArrays = []
      const storeArray = isEmpty(state.condition.storeArrays)?[]:state.condition.storeArrays
      for (let i = 0; i < storeArray.length; i++) {
        storeArrays.push(storeArray[i].value)
      }
      axios({
            url: '/api/exportexcelfile/createStatementReceiveFinanceExcel',
            method: 'get',
            params: {
              receiveNo: state.condition.receiveNo,
              statementNo: state.condition.statementNo,
              relationalNo: state.condition.relationalNo,
              payMethod: state.condition.payMethod == null ? null : state.condition.payMethod.value,
              status: tabStatus.value,
              createTime: state.condition.createTime,
              updateTime: state.condition.updateTime,
              reachTime: state.condition.reachTime,
              channelId: state.condition.channelId,
              warehouseId: state.condition.warehouseId,
              storeIds:storeArrays.join(','),
              companyId: state.condition.companyId,
              creator: state.condition.creatorId,
              type: routeType.value,
              source:state.condition.source == null ? null : state.condition.source.value,
              statementPayMethod:state.condition.statementPayMethod,
            },
            responseType: 'blob',
          }
      ).then(res => {
        toast.success("导出成功")
        let data = new Blob([res.data], {type: 'application/vnd.ms-excel'})   //接收的是blob，若接收的是文件流，需要转化一下
        let filename = "收款单-" + new Date().getTime();
        /*-------------*/
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = URL.createObjectURL(data);
        link.setAttribute('download', filename + '.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      tabStatus,
      routeType,

      // UI
    } = statementreceiveUseList({
      type: router.route.value.query.type
    })

    return {
      ...toRefs(state),
      state,
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      routeType,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      isEmpty,
      // UI
      toTime,
      toDate,
      advanced_search,
      fromChildren,
      changeStatus,
      tabStatus,
      saveRemark,
      del,
      reject,
      batchAudit,
      exportData,
      FinanceExportData,
    }
  },
  watch: {
    $route(to, from) {
      this.type = this.$route.query.type
      this.routeType = this.$route.query.type
      this.refetchData()
    },
    type: {
      handler(newValue, oldValue) {
        this.routeName = newValue == 1 ? 'apps-statementreceive-ol-edit' : 'apps-statementreceive-edit'
      }
    },
    selected: {
      handler(newValue, oldValue) {
        this.price = 0
        this.actualAmount = 0
        this.feeAmount = 0
        for (var i = 0; i < this.selected.length; i++) {
          this.price += this.selected[i].ext.receiveTotal
          this.actualAmount += this.selected[i].ext.srmTotal
          this.feeAmount += this.selected[i].ext.scTotal
        }
        this.price = this.price.toFixed(2)
        this.actualAmount = this.actualAmount.toFixed(2)
        this.feeAmount = this.feeAmount.toFixed(2)
      }
    }
  },
  created() {
    const userData = getUserData()
    this.user = userData
    // this.tabStatus = this.$route.params.status || '2'
    // this.status = this.$route.params.status || '2'
    this.type = this.$route.query.type
    if (userData.role_id == 14 || userData.role_id == 12) {
      this.allowUser = true
    }
    store.dispatch('store/search', {all:1})
        .then(response => {
          let data = response.data.data.list
          for (let i = 0; i < data.length; i++) {
            this.storeArray.push({'key': i, "label": data[i].store_name, "value": data[i].store_id})
          }
        })
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
